import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const FileManagerDashboard = () => {
  return (
    <Layout>
      <Seo
        title="How to Use Synkli's File Manager Dashboard: Complete Guide"
        description={`Discover Synkli's File Manager Dashboard with our comprehensive mobile app user guide. Learn to manage, share, and store tax-related documents.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`file-manager-dashboard`} />
    </Layout>
  )
}

export default FileManagerDashboard
